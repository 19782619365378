import useAuth from "./useAuth";


const useLogout =  ()=> {
    
    const {setAuth} = useAuth();
    const logout = async ()=> {
    setAuth({});
    localStorage.removeItem("token");

    }

   return logout;
}

export default useLogout;