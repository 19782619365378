import React from 'react'
import useAuth from "../hooks/useAuth";
import Playlist from "../components/place/Playlist";
export default function Place() {

    const {placeDetail} =useAuth();
  return (
    <>
    <Playlist place={placeDetail}/>
    </>
  )

}

