import React, { useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth';
import PlaceService from "../../services/PlaceService";
import Sidebar from '../../views/myPlace/sidebar';
import PlaceListItem from './PlaceListItem';

function PlaceList() {
    let placeService = new PlaceService();
    const [places, setPlaces] = useState([]);
    const {lang} = useAuth();
    useEffect(() => {
        placeService.getMyPlaces().then(res => {
            setPlaces(res?.data)
        }).catch(err => { return; })
    }, [])

    return (
        <>
            <section className="page">
                <div className="container">
                    <div className="row">
                        <Sidebar></Sidebar>
                        <div className="col-12 col-lg-8 res-margin col">
                            <article className="row blog-post">
                                {places == [] || places == undefined || places == null || places == false
                                    ? <div>{lang.place.noMyPlace}</div>
                                    : places?.map(place => {
                                        return (
                                            <ul key={place._id} className="col-md-12 list-group">
                                                <PlaceListItem place={place} />
                                            </ul>
                                        )
                                    })
                                }
                            </article>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default PlaceList
