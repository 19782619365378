import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserHistory } from "history";
import {AuthProvider} from "./context/AuthProvider";
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import I18n from 'redux-i18n';

const history = createBrowserHistory();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router history={history}>
        <AuthProvider>

           <App/>
          
        </AuthProvider>
  </Router>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
