// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyDYD5Sva3HsxQDWKSx97-0Ji7CoV4fxDLk",
  authDomain: "votingen-97d44.firebaseapp.com",
  databaseURL: "https://votingen-97d44.firebaseio.com",
  projectId: "votingen-97d44",
  storageBucket: "votingen-97d44.appspot.com",
  messagingSenderId: "1009434355900",
  appId: "1:1009434355900:web:80924f5688ff3428e188b9"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);





export  {auth};