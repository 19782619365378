import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';

const LayoutDefault = ({ children }) => (
  <div>
  <div className="wrapper">

    {window.location.href.indexOf("mobile") != -1 ? 
    <>
    {children}
  </> : 
  <>
  <Header/>
  {children}
<Footer />
</>
    }
    
  </div>
  </div>
);

export default LayoutDefault;  