import React from 'react'

const Privacy = () => {

  document.title = localStorage.getItem('webLang') == "tr" ? 'Privacy Policy | Votingen' : 'KVKK | Votingen';

  return (

    <section className="page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 res-margin col">
            <article className="row blog-post">

              {localStorage.getItem('webLang') == "tr" ?

                <div className="col-12 col">

                  <h1>
                    <font>
                      <font>Gizlilik Politikası</font>
                    </font>
                  </h1>
                  <div>
                    <p>
                      <font>
                        <font>Son güncelleme: 15 Haziran 2022</font>
                      </font>
                    </p>
                    <p>
                      <font>
                        <font>Bu Gizlilik Politikası, Hizmeti kullandığınızda bilgilerinizin toplanması, kullanılması ve ifşa edilmesine ilişkin politikalarımızı ve prosedürlerimizi açıklar ve Size gizlilik haklarınızı ve yasaların Sizi nasıl koruduğunu anlatır.</font>
                      </font>
                    </p>
                    <p>
                      <font>
                        <font>Kişisel verilerinizi Hizmeti sağlamak ve iyileştirmek için kullanırız. </font>
                        <font>Hizmeti kullanarak, bu Gizlilik Politikasına uygun olarak bilgilerin toplanmasını ve kullanılmasını kabul edersiniz. </font>
                        </font>

                    </p>
                    <h1>
                      <font>
                        <font>Yorum ve Tanımlar</font>
                      </font>
                    </h1>
                    
                    <h2>
                      <font>
                        <font>Tanımlar</font>
                      </font>
                    </h2>
                    <p>
                      <font>
                        <font>Bu Gizlilik Politikasının amaçları için:</font>
                      </font>
                    </p>
                    <ul>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Hesap</font>
                            </font>
                          </strong>
                          <font>
                            <font> , Hizmetimize veya Hizmetimizin bölümlerine erişmeniz için Sizin için oluşturulan benzersiz bir hesap anlamına gelir.</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Bağlı</font>
                            </font>
                          </strong>
                          <font>
                            <font> kuruluş, bir taraf tarafından kontrol edilen, kontrol edilen veya bir tarafla ortak kontrol altında olan bir kuruluş anlamına gelir; burada "kontrol", yönetim kurulu veya diğer yönetim otoritesi seçiminde oy kullanma hakkına sahip hisselerin, özsermaye paylarının veya diğer menkul kıymetlerin %50 veya daha fazlasına sahip olmak anlamına gelir. .</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Uygulama</font>
                            </font>
                          </strong>
                          <font>
                            <font> , votingen adlı herhangi bir elektronik cihaza Sizin tarafınızdan indirilen Şirket tarafından sağlanan yazılım programı anlamına gelir.</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Şirket</font>
                            </font>
                          </strong>
                          <font>
                            <font> (bu Sözleşmede "Şirket", "Biz", "Biz" veya "Bizim" olarak anılacaktır) Devbu Bilişim Anonim Şirketi, Suit Tower B43 06810 Ankara'yı ifade eder.</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Ülkeye</font>
                            </font>
                          </strong>
                          <font>
                            <font> atıfta bulunur: Türkiye</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Cihaz</font>
                            </font>
                          </strong>
                          <font>
                            <font> , bilgisayar, cep telefonu veya dijital tablet gibi Hizmete erişebilen herhangi bir cihaz anlamına gelir.</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Kişisel Veri</font>
                            </font>
                          </strong>
                          <font>
                            <font> , kimliği belirli veya belirlenebilir bir gerçek kişiye ilişkin her türlü bilgidir.</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Hizmet</font>
                            </font>
                          </strong>
                          <font>
                            <font> , Uygulamaya atıfta bulunur.</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Hizmet Sağlayıcı</font>
                            </font>
                          </strong>
                          <font>
                            <font> , verileri Şirket adına işleyen herhangi bir gerçek veya tüzel kişi anlamına gelir. </font>
                            <font>Hizmeti kolaylaştırmak, Şirket adına Hizmeti sağlamak, Hizmetle ilgili hizmetleri gerçekleştirmek veya Şirkete Hizmetin nasıl kullanıldığını analiz etmede yardımcı olmak için Şirket tarafından istihdam edilen üçüncü taraf şirketleri veya bireyleri ifade eder.</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Kullanım Verileri</font>
                            </font>
                          </strong>
                          <font>
                            <font> , Hizmetin kullanımıyla veya Hizmet altyapısının kendisinden (örneğin, bir sayfa ziyaretinin süresi) otomatik olarak toplanan verileri ifade eder.</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Hizmete erişen veya Hizmet'i</font>
                            </font>
                          </strong>
                          <font>
                            <font> kullanan kişi veya söz konusu kişinin adına Hizmet'e eriştiği veya Hizmet'i kullandığı şirket veya diğer tüzel kişilik anlamına gelir.</font>
                          </font>

                        </p>
                      </li>
                    </ul>
                    <h1>
                      <font>
                        <font>Kişisel Verilerinizi Toplama ve Kullanma</font>
                      </font>
                    </h1>
                    <h2>
                      <font>
                        <font>Toplanan Veri Türleri</font>
                      </font>
                    </h2>
                    <h3>
                      <font>
                        <font>Kişisel veri</font>
                      </font>
                    </h3>
                    <p>
                      <font>
                        <font>Hizmetimizi kullanırken, Sizden, Sizinle iletişim kurmak veya sizi tanımlamak için kullanılabilecek belirli kişisel olarak tanımlanabilir bilgileri sağlamanızı isteyebiliriz. </font>
                        <font>Kişisel olarak tanımlanabilir bilgiler aşağıdakileri içerebilir, ancak bunlarla sınırlı değildir:</font>
                      </font>
                    </p>
                    <ul>
                      <li>
                        <p>
                          <font>
                            <font>Ad ve soyad</font>
                          </font>
                        </p>
                      </li>
                      <li>
                        <p>
                          <font>
                            <font>Telefon numarası</font>
                          </font>
                        </p>
                      </li>
                      <li>
                        <p>
                          <font>
                            <font>Kullanım verisi</font>
                          </font>
                        </p>
                      </li>
                    </ul>
                    <h3>
                      <font>
                        <font>Kullanım verisi</font>
                      </font>
                    </h3>
                    <p>
                      <font>
                        <font>Kullanım Verileri, Hizmet kullanılırken otomatik olarak toplanır.</font>
                      </font>
                    </p>
                    <p>
                      <font>
                        <font>Kullanım Verileri, Cihazınızın İnternet Protokolü adresi (örn. IP adresi), tarayıcı türü, tarayıcı sürümü, ziyaret ettiğiniz Hizmetimizin sayfaları, ziyaretinizin saati ve tarihi, bu sayfalarda geçirilen süre, benzersiz cihaz gibi bilgileri içerebilir. tanımlayıcılar ve diğer teşhis verileri.</font>
                      </font>
                    </p>
                    <p>
                      <font>
                        <font>Hizmete bir mobil cihaz aracılığıyla veya aracılığıyla eriştiğinizde, kullandığınız mobil cihazın türü, mobil cihazınızın benzersiz kimliği, mobil cihazınızın IP adresi, cep telefonunuz dahil ancak bunlarla sınırlı olmamak üzere belirli bilgileri otomatik olarak toplayabiliriz. işletim sistemi, kullandığınız mobil İnternet tarayıcısının türü, benzersiz cihaz tanımlayıcıları ve diğer tanılama verileri.</font>
                      </font>
                    </p>
                    <p>
                      <font>
                        <font>Hizmetimizi her ziyaret ettiğinizde veya bir mobil cihaz aracılığıyla veya aracılığıyla Hizmete eriştiğinizde tarayıcınızın gönderdiği bilgileri de toplayabiliriz.</font>
                      </font>
                    </p>
                    <h3>
                      <font>
                        <font>Uygulamayı Kullanırken Toplanan Bilgiler</font>
                      </font>
                    </h3>
                    <p>
                      <font>
                        <font>Uygulamamızı kullanırken, Uygulamamızın özelliklerini sağlamak için önceden izninizle şunları toplayabiliriz:</font>
                      </font>
                    </p>
                    <ul>
                      <li>
                        <p>
                          <font>
                            <font>Konumunuzla ilgili bilgiler</font>
                          </font>
                        </p>
                      </li>
                      <li>
                        <p>
                          <font>
                            <font>Cihazınızın kamera ve fotoğraf kitaplığından resimler ve diğer bilgiler</font>
                          </font>
                        </p>
                      </li>
                    </ul>
                    <p>
                      <font>
                        <font>Bu bilgileri Hizmetimizin özelliklerini sağlamak, Hizmetimizi geliştirmek ve özelleştirmek için kullanırız. </font>
                        <font>Bilgiler Şirketin sunucularına ve/veya bir Hizmet Sağlayıcının sunucusuna yüklenebilir veya basitçe Sizin cihazınızda saklanabilir.</font>
                      </font>
                    </p>
                    <p>
                      <font>
                        <font>Bu bilgilere erişimi istediğiniz zaman Cihaz ayarlarından etkinleştirebilir veya devre dışı bırakabilirsiniz.</font>
                      </font>
                    </p>
                    <h2>
                      <font>
                        <font>Kişisel Verilerinizin Kullanımı</font>
                      </font>
                    </h2>
                    <p>
                      <font>
                        <font>Şirket, Kişisel Verileri aşağıdaki amaçlarla kullanabilir:</font>
                      </font>
                    </p>
                    <ul>
                      <li>
                        <p>
                          <strong>
                            <font></font>
                          </strong>
                          <font>
                            <font>Hizmetimizin kullanımını izlemek de dahil olmak üzere </font>
                            <strong>
                              <font>Hizmetimizi sağlamak ve sürdürmek .</font>
                            </strong>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Hesabınızı</font>
                            </font>
                          </strong>
                          <font>
                            <font> yönetmek için: Hizmetin bir kullanıcısı olarak kaydınızı yönetmek için. </font>
                            <font>Sağladığınız Kişisel Veriler, Kayıtlı bir kullanıcı olarak Size sunulan Hizmetin farklı işlevlerine erişmenizi sağlayabilir.</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Bir sözleşmenin ifası için:</font>
                            </font>
                          </strong>
                          <font>
                            <font> Satın aldığınız ürünler, öğeler veya hizmetler için satın alma sözleşmesinin veya Hizmet aracılığıyla Bizimle yaptığımız herhangi bir başka sözleşmenin geliştirilmesi, uygunluğu ve üstlenilmesi.</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Sizinle iletişime geçmek için:</font>
                            </font>
                          </strong>
                          <font>
                            <font> Sizinle e-posta, telefon görüşmeleri, SMS veya bir mobil uygulamanın güncellemelerle ilgili anlık bildirimleri veya güvenlik güncellemeleri dahil işlevler, ürünler veya sözleşmeli hizmetlerle ilgili bilgilendirici iletişimler gibi diğer eşdeğer elektronik iletişim biçimleriyle iletişime geçmek, uygulanması için gerekli veya makul olduğunda.</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font></font>
                          </strong>
                          <font>
                            <font>Bu tür bilgileri almamayı seçmediğiniz sürece, halihazırda satın aldığınız veya sorduğunuz ürünlere benzer sunduğumuz diğer mallar, hizmetler ve etkinlikler hakkında </font>
                            <strong>
                              <font>Size haberler, özel teklifler ve genel bilgiler sağlamak için.</font>
                            </strong>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Taleplerinizi yönetmek için:</font>
                            </font>
                          </strong>
                          <font>
                            <font> Bize gelen taleplerinize katılmak ve yönetmek için.</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>İş transferleri için:</font>
                            </font>
                          </strong>
                          <font>
                            <font> Bilgilerinizi bir birleşme, elden çıkarma, yeniden yapılandırma, yeniden yapılanma, feshetme veya varlıklarımızın bir kısmının veya tamamının sürekli olarak veya iflas, tasfiyenin bir parçası olarak değerlendirilmesi veya yürütülmesi için kullanabiliriz. veya Hizmet kullanıcılarımız hakkında tarafımızca tutulan Kişisel Verilerin devredilen varlıklar arasında yer aldığı benzer bir işlem.</font>
                          </font>

                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <font>
                              <font>Diğer amaçlar için</font>
                            </font>
                          </strong>
                          <font>
                            <font> : Bilgilerinizi veri analizi, kullanım eğilimlerini belirleme, promosyon kampanyalarımızın etkinliğini belirleme ve Hizmet, ürünlerimiz, hizmetlerimiz, pazarlama ve deneyiminizi değerlendirmek ve geliştirmek gibi başka amaçlar için kullanabiliriz.</font>
                          </font>

                        </p>
                      </li>
                    </ul>
                    <p>
                      <font>
                        <font>Kişisel bilgilerinizi aşağıdaki durumlarda paylaşabiliriz:</font>
                      </font>
                    </p>
                    <ul>
                      <li>
                        <strong>
                          <font>
                            <font>Hizmet Sağlayıcılarla:</font>
                          </font>
                        </strong>
                        <font>
                          <font> Kişisel bilgilerinizi Hizmetimizin kullanımını izlemek ve analiz etmek, Sizinle iletişime geçmek için Hizmet Sağlayıcılarla paylaşabiliriz.</font>
                        </font>

                      </li>
                      <li>
                        <strong>
                          <font>
                            <font>İş transferleri için:</font>
                          </font>
                        </strong>
                        <font>
                          <font> Kişisel bilgilerinizi herhangi bir birleşme, Şirket varlıklarının satışı, finansman veya işimizin tamamının veya bir kısmının başka bir şirkete satın alınmasıyla bağlantılı olarak veya müzakereler sırasında paylaşabilir veya devredebiliriz.</font>
                        </font>

                      </li>
                      <li>
                        <strong>
                          <font>
                            <font>Bağlı Kuruluşlarla:</font>
                          </font>
                        </strong>
                        <font>
                          <font> Bilgilerinizi bağlı şirketlerimizle paylaşabiliriz, bu durumda bu bağlı kuruluşların bu Gizlilik Politikasına uymasını zorunlu kılacağız. </font>
                          <font>İştirakler, ana şirketimizi ve bizim kontrol ettiğimiz veya Bizimle ortak kontrolümüz altındaki diğer bağlı kuruluşları, ortak girişim ortaklarını veya diğer şirketleri içerir.</font>
                        </font>

                      </li>
                      <li>
                        <strong>
                          <font>
                            <font>İş ortaklarıyla:</font>
                          </font>
                        </strong>
                        <font>
                          <font> Size belirli ürünler, hizmetler veya promosyonlar sunmak için Bilgilerinizi iş ortaklarımızla paylaşabiliriz.</font>
                        </font>

                      </li>
                      <li>
                        <strong>
                          <font>
                            <font>Diğer kullanıcılarla:</font>
                          </font>
                        </strong>
                        <font>
                          <font> Kişisel bilgileri paylaştığınız veya diğer kullanıcılarla ortak alanlarda başka bir şekilde etkileşime girdiğiniz zaman, bu tür bilgiler tüm kullanıcılar tarafından görüntülenebilir ve dışarıda herkese açık olarak dağıtılabilir.</font>
                        </font>

                      </li>
                      <li>
                        <strong>
                          <font>
                            <font>Sizin izninizle</font>
                          </font>
                        </strong>
                        <font>
                          <font> : Kişisel bilgilerinizi, sizin izninizle başka herhangi bir amaç için ifşa edebiliriz.</font>
                        </font>

                      </li>
                    </ul>
                    <h2>
                      <font>
                        <font>Kişisel Verilerinizin Saklanması</font>
                      </font>
                    </h2>
                    <p>
                      <font>
                        <font>Şirket, Kişisel Verilerinizi yalnızca bu Gizlilik Politikasında belirtilen amaçlar için gerekli olduğu sürece saklayacaktır. </font>
                        <font>Kişisel Verilerinizi, yasal yükümlülüklerimize uymak (örneğin, geçerli yasalara uymak için verilerinizi tutmamız gerekiyorsa), anlaşmazlıkları çözmek ve yasal anlaşmalarımızı ve politikalarımızı uygulamak için gerekli olduğu ölçüde saklayacak ve kullanacağız.</font>
                      </font>
                    </p>
                    <p>
                      <font>
                        <font>Şirket ayrıca Kullanım Verilerini dahili analiz amacıyla saklayacaktır. </font>
                        <font>Kullanım Verileri, bu verilerin güvenliğini güçlendirmek veya Hizmetimizin işlevselliğini geliştirmek için kullanılması veya yasal olarak bu verileri daha uzun süreler boyunca tutmak zorunda olmamız dışında, genellikle daha kısa bir süre boyunca saklanır.</font>
                      </font>
                    </p>
                    <h2>
                      <font>
                        <font>Kişisel Verilerinizin Aktarılması</font>
                      </font>
                    </h2>
                    <p>
                      <font>
                        <font>Kişisel Verileriniz de dahil olmak üzere bilgileriniz, Şirketin faaliyet ofislerinde ve işlemeye dahil olan tarafların bulunduğu diğer tüm yerlerde işlenir. </font>
                        <font>Bu, bu bilgilerin eyaletinizin, ilinizin, ülkenizin veya veri koruma yasalarının Sizin yetki alanınızdan farklı olabileceği diğer resmi yargı yetkisinin dışında bulunan bilgisayarlara aktarılabileceği ve bu bilgisayarlarda saklanabileceği anlamına gelir.</font>
                      </font>
                    </p>
                    <p>
                      <font>
                        <font>Bu Gizlilik Politikasına verdiğiniz onayın ardından bu tür bilgileri göndermeniz, bu aktarıma ilişkin sözleşmenizi temsil eder.</font>
                      </font>
                    </p>
                    <p>
                      <font>
                        <font>Şirket, verilerinizin güvenli bir şekilde ve bu Gizlilik Politikasına uygun olarak ele alındığından emin olmak için makul olarak gerekli tüm adımları atacaktır ve Kişisel Verilerinizin güvenliği de dahil olmak üzere yeterli kontroller olmadıkça bir kuruluşa veya ülkeye aktarılmayacaktır. Verileriniz ve diğer kişisel bilgileriniz.</font>
                      </font>
                    </p>
                    <h2>
                      <font>
                        <font>Kişisel Verilerinizin İfşası</font>
                      </font>
                    </h2>
                    <h3>
                      <font>
                        <font>Ticari işlemler</font>
                      </font>
                    </h3>
                    <p>
                      <font>
                        <font>Şirketin bir birleşme, devralma veya varlık satışına dahil olması durumunda Kişisel Verileriniz aktarılabilir. </font>
                        <font>Kişisel Verileriniz aktarılmadan ve farklı bir Gizlilik Politikasına tabi olmadan önce bildirimde bulunacağız.</font>
                      </font>
                    </p>
                    <h3>
                      <font>
                        <font>Kanun yaptırımı</font>
                      </font>
                    </h3>
                    <p>
                      <font>
                        <font>Belirli koşullar altında, kanunen veya resmi makamların (örneğin bir mahkeme veya bir devlet kurumu) geçerli taleplerine yanıt olarak gerekli olması halinde Şirketin Kişisel Verilerinizi ifşa etmesi gerekebilir.</font>
                      </font>
                    </p>
                    <h3>
                      <font>
                        <font>Diğer yasal gereklilikler</font>
                      </font>
                    </h3>
                    <p>
                      <font>
                        <font>Şirket, Kişisel Verilerinizi, bu tür bir eylemin aşağıdakiler için gerekli olduğuna iyi niyetle inanarak ifşa edebilir:</font>
                      </font>
                    </p>
                    <ul>
                      <li>
                        <font>
                          <font>Yasal bir yükümlülüğe uymak</font>
                        </font>
                      </li>
                      <li>
                        <font>
                          <font>Şirketin haklarını veya mülkiyetini korumak ve savunmak</font>
                        </font>
                      </li>
                      <li>
                        <font>
                          <font>Hizmetle bağlantılı olası suistimalleri önlemek veya araştırmak</font>
                        </font>
                      </li>
                      <li>
                        <font>
                          <font>Hizmet Kullanıcılarının veya kamunun kişisel güvenliğini korumak</font>
                        </font>
                      </li>
                      <li>
                        <font>
                          <font>Yasal sorumluluğa karşı korumak</font>
                        </font>
                      </li>
                    </ul>
                    <h2>
                      <font>
                        <font>Kişisel Verilerinizin Güvenliği</font>
                      </font>
                    </h2>
                    <p>
                      <font>
                        <font>Kişisel Verilerinizin güvenliği Bizim için önemlidir, ancak İnternet üzerinden hiçbir aktarım yönteminin veya elektronik depolama yönteminin %100 güvenli olmadığını unutmayın. </font>
                        <font>Kişisel Verilerinizi korumak için ticari olarak kabul edilebilir araçları kullanmaya çabalarken, bunların mutlak güvenliğini garanti edemeyiz.</font>
                      </font>
                    </p>
                    <h1>
                      <font>
                        <font>Çocukların Gizliliği</font>
                      </font>
                    </h1>
                    <p>
                      <font>
                        <font>Hizmetimiz 18 yaşın altındaki hiç kimseye hitap etmez. 18 yaşın altındaki hiç kimseden bilerek kişisel olarak tanımlanabilir bilgiler toplamıyoruz. Bir ebeveyn iseniz ve Çocuğunuzun Bize Kişisel Veriler sağladığının farkındaysanız, lütfen Bize Ulaşın. </font>
                        <font>Ebeveyn izni doğrulaması olmadan 18 yaşın altındaki herhangi birinden Kişisel Veri topladığımızı fark edersek, bu bilgileri sunucularımızdan kaldırmak için adımlar atarız.</font>
                      </font>
                    </p>
                    <p>
                      <font>
                        <font>Bilgilerinizi işlemek için yasal dayanak olarak rızaya güvenmemiz gerekiyorsa ve ülkeniz bir ebeveynden onay gerektiriyorsa, bu bilgileri toplamadan ve kullanmadan önce ebeveyninizin onayını isteyebiliriz.</font>
                      </font>
                    </p>
                    <h1>
                      <font>
                        <font>Diğer Web Sitelerine Bağlantılar</font>
                      </font>
                    </h1>
                    <p>
                      <font>
                        <font>Hizmetimiz, tarafımızca işletilmeyen diğer web sitelerine bağlantılar içerebilir. </font>
                        <font>Bir üçüncü şahıs bağlantısına tıklarsanız, o üçüncü şahsın sitesine yönlendirileceksiniz. </font>
                        <font>Ziyaret ettiğiniz her sitenin Gizlilik Politikasını incelemenizi şiddetle tavsiye ederiz.</font>
                      </font>
                    </p>
                    <p>
                      <font>
                        <font>Herhangi bir üçüncü taraf site veya hizmetinin içeriği, gizlilik politikaları veya uygulamaları üzerinde hiçbir kontrole sahip değiliz ve sorumluluk kabul etmiyoruz.</font>
                      </font>
                    </p>
                    <h1>
                      <font>
                        <font>Bu Gizlilik Politikasındaki Değişiklikler</font>
                      </font>
                    </h1>
                    <p>
                      <font>
                        <font>Gizlilik Politikamızı zaman zaman güncelleyebiliriz. </font>
                        <font>Bu sayfada yeni Gizlilik Politikasını yayınlayarak herhangi bir değişiklik hakkında Sizi bilgilendireceğiz.</font>
                      </font>
                    </p>
                    <p>
                      <font>
                        <font>Değişikliğin yürürlüğe girmesinden ve bu Gizlilik Politikasının üst kısmındaki "Son güncelleme" tarihini güncellemeden önce Size e-posta ve/veya Hizmetimizle ilgili belirgin bir bildirim yoluyla bilgi vereceğiz.</font>
                      </font>
                    </p>
                    <p>
                      <font>
                        <font>Herhangi bir değişiklik için bu Gizlilik Politikasını periyodik olarak gözden geçirmeniz tavsiye edilir. </font>
                        <font>Bu Gizlilik Politikasında yapılan değişiklikler, bu sayfada yayınlandıklarında geçerlidir.</font>
                      </font>
                    </p>
                    <h1>
                      <font>
                        <font>Bize Ulaşın</font>
                      </font>
                    </h1>
                    <p>
                      <font>
                        <font>Bu Gizlilik Politikası ile ilgili herhangi bir sorunuz varsa, bizimle iletişime geçebilirsiniz:</font>
                      </font>
                    </p>
                    <ul>
                      <li>
                        <p>
                          <font>
                            <font>E-posta ile: </font>
                          </font>
                          <font>
                            <font>info@votingen.com</font>
                          </font>
                        </p>
                      </li>
                      <li>
                        <p>
                          <font>
                            <font>Web sitemizde bu sayfayı ziyaret ederek: </font>
                            <a href="https://votingen.com/contact" rel="external nofollow noopener" target="_blank">
                              <font>https://votingen.com/contact</font>
                            </a>
                          </font>

                          <a href="https://votingen.com/contact" rel="external nofollow noopener" target="_blank">
                            <font></font>
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                :
                <div className="col-12 col">
                  <h1>Privacy Policy </h1>
                  <div>
                    <p>Last updated: June 15, 2022</p>
                    <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                    <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/" target="_blank">Free Privacy Policy Generator</a>. </p>
                    <h1>Interpretation and Definitions</h1>
                    <h2>Interpretation</h2>
                    <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                    <h2>Definitions</h2>
                    <p>For the purposes of this Privacy Policy:</p>
                    <ul>
                      <li>
                        <p>
                          <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named votingen
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Devbu Bilişim Anonim Şirketi, Suit Tower B43 06810 Ankara.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Country</strong> refers to: Turkey
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Service</strong> refers to the Application.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                        </p>
                      </li>
                    </ul>
                    <h1>Collecting and Using Your Personal Data</h1>
                    <h2>Types of Data Collected</h2>
                    <h3>Personal Data</h3>
                    <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                    <ul>
                      <li>
                        <p>First name and last name</p>
                      </li>
                      <li>
                        <p>Phone number</p>
                      </li>
                      <li>
                        <p>Usage Data</p>
                      </li>
                    </ul>
                    <h3>Usage Data</h3>
                    <p>Usage Data is collected automatically when using the Service.</p>
                    <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                    <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                    <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
                    <h3>Information Collected while Using the Application</h3>
                    <p>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:</p>
                    <ul>
                      <li>
                        <p>Information regarding your location</p>
                      </li>
                      <li>
                        <p>Pictures and other information from your Device's camera and photo library</p>
                      </li>
                    </ul>
                    <p>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.</p>
                    <p>You can enable or disable access to this information at any time, through Your Device settings.</p>
                    <h2>Use of Your Personal Data</h2>
                    <p>The Company may use Personal Data for the following purposes:</p>
                    <ul>
                      <li>
                        <p>
                          <strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>To manage Your requests:</strong> To attend and manage Your requests to Us.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.
                        </p>
                      </li>
                    </ul>
                    <p>We may share Your personal information in the following situations:</p>
                    <ul>
                      <li>
                        <strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.
                      </li>
                      <li>
                        <strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
                      </li>
                      <li>
                        <strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
                      </li>
                      <li>
                        <strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.
                      </li>
                      <li>
                        <strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.
                      </li>
                      <li>
                        <strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.
                      </li>
                    </ul>
                    <h2>Retention of Your Personal Data</h2>
                    <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                    <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
                    <h2>Transfer of Your Personal Data</h2>
                    <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                    <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                    <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                    <h2>Disclosure of Your Personal Data</h2>
                    <h3>Business Transactions</h3>
                    <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                    <h3>Law enforcement</h3>
                    <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                    <h3>Other legal requirements</h3>
                    <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                    <ul>
                      <li>Comply with a legal obligation</li>
                      <li>Protect and defend the rights or property of the Company</li>
                      <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                      <li>Protect the personal safety of Users of the Service or the public</li>
                      <li>Protect against legal liability</li>
                    </ul>
                    <h2>Security of Your Personal Data</h2>
                    <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                    <h1>Children's Privacy</h1>
                    <p>Our Service does not address anyone under the age of 18. We do not knowingly collect personally identifiable information from anyone under the age of 18. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 18 without verification of parental consent, We take steps to remove that information from Our servers.</p>
                    <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
                    <h1>Links to Other Websites</h1>
                    <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                    <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                    <h1>Changes to this Privacy Policy</h1>
                    <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                    <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
                    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                    <h1>Contact Us</h1>
                    <p>If you have any questions about this Privacy Policy, You can contact us:</p>
                    <ul>
                      <li>
                        <p>By email: info@votingen.com</p>
                      </li>
                      <li>
                        <p>By visiting this page on our website: <a href="https://votingen.com/contact" rel="external nofollow noopener" target="_blank">https://votingen.com/contact</a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              }
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Privacy
