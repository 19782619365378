import React from 'react'
import {Link} from "react-router-dom"
import useAuth from "../../hooks/useAuth";
import YouTubeIcon from '@mui/icons-material/YouTube';

function PlaceListItem(props) {
   const {setPlaceDetail}=useAuth();
   
    return (
        <div className="container">
           {/* <Link to={`/${props.place._id}`}> */}
           <Link to={`/place/${props.place._id}`}>
        <li  onClick={()=> {setPlaceDetail(props.place)}} className="list-group-item text-decoration-none">
       
			<div className="media">
				<div className="media-left">
					<img className="media-object mr-6" width={"200px;"} height={"100px;"} src={props.place.cover_photo}/>
				</div>
				<div className="media-body">
					<div className="media-heading">
                       
                       <h3 className="mt-0">{props.place.name}</h3>
                        {props.place.about}
						
					</div>
                    
				</div>
                <div className="media-right">
                    <div className="media-object">merhaba</div>
                </div>
              
                <Link to={`/connect/youtube/${props?.place?._id}`}><div>  <span><YouTubeIcon/>Youtube Hesabını Bağla</span></div></Link> 
			</div>
         
		</li>
        
        </Link>
        {/* {//eğer Bağlıysa Göster bağlı değilse gösterme} */}
       
        </div>
    )
}

export default PlaceListItem
