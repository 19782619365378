import React, { useRef, useEffect } from 'react';
import { useLocation, Routes, Route, BrowserRouter } from 'react-router-dom';
// Layouts
import LayoutDefault from '../layouts/LayoutDefault';


// Views 
import Home from '../views/Home';
import Privacy from '../views/pages/privacy'
import Contact from '../views/pages/contact'
import Terms from '../views/pages/terms'
import Thanks from '../views/pages/thanks'
import Connect from '../views/pages/connect'
import Place from "../views/Place";


// Auth
import Register from '../components/auth/Register';
import Login from '../components/auth/Login';
import RequireAuth from "../components/auth/RequireAuth";

// Place
import PlaceList from "../components/place/PlaceList";
import PlaceListItem from "../components/place/PlaceListItem";
import Playlist from "../components/place/Playlist";
import AddPlace from '../components/place/AddPlace';

// Error
import Unauthorized from "../components/error/Unauthorized";
import Missing from "../components/error/Missing";
import PlaceDetail from '../views/PlaceDetail';
import Eula from '../views/pages/eula';

export default function Router() {

  return (
    <>
      {/* public routes */}
      <LayoutDefault>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="eula" element={<Eula />} />
          <Route path="/connect/youtube/:placeId" element={<Connect />} />
          <Route path="thanks" element={<Thanks />} />
          <Route path="terms" element={<Terms />} />
          <Route path="contact" element={<Contact />} />

          {/* we want to protect these routes */}

          <Route element={<RequireAuth />} >
            <Route path="place" element={<PlaceList />} />
            <Route path="place/:placeId" element={<PlaceDetail />} />
            <Route path="playlist" element={<Place />} />
            <Route path="add-place" element={<AddPlace />} />
          </Route>

          <Route path="mobile/terms" element={<Terms />} />
          <Route path="mobile/contact" element={<Contact />} />
          <Route path="mobile/privacy" element={<Privacy />} />
          <Route path="mobile/eula" element={<Eula />} />
          <Route path="*" element={<Missing />} />
        </Routes>
      </LayoutDefault>
      {/* catch all */}
    </>
  );
}
