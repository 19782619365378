import axios from 'axios'
import axios_api from "../api/axios";
const token =localStorage.getItem('token')
export default class YoutubeService {
  

  async search(api_key,part,type,term) {
    const url="https://www.googleapis.com/youtube/v3/search"
    let params = { key:api_key,part,type,q:term }
    const response=await axios.get(url,{params})
      .then(function (response) {
       
        return response.data
      })
      .catch(function (error) {
        return;
      })
      return response;
  }

  async connect(place_id) {
    
  
    const url =`/place/connect/youtube/${place_id}`
    const headers={headers: { Authorization: `Bearer ` + token }}
    
    return axios_api.post(url,
     {},headers
        )
    .then(res =>{ return res})
    .catch(err=>{return;})
 
  }
  





}