import axios from '../api/axios';
const token =localStorage.getItem('token');
const headers={headers: { Authorization: `Bearer ` + token}}
export default class PlaceService {
  

  async getMyPlaces() {
    const url = `/profile/place/myPlaces`
    const response= await axios.post(url,{},headers)
 
  return response;

  }

  async getPlaceById ( place_id) {
    const url=`/place/${place_id}`;
    const response = await axios.get(url,headers);
    return response;
    
    }
}