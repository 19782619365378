import React from 'react'
import { Link } from 'react-router-dom'
import CookieConsent from 'react-cookie-consent'

const AppFooter = () => {

  const setLanguage = (lang) => {
    localStorage.setItem("webLang", lang);
    setTimeout(function() {
      window.location.reload()
    },1000)
  }

  return (
    <>
      <footer>
        <div className="footer-widgets">
          <div className="container">
            <div className="row">
              <div className="col-3 col-md-3 col-lg-3 res-margin col">
                <div className="widget">
                  <p className="footer-logo">
                    <img src="/images/logo-white@2x.png" alt="votingen" />
                  </p>
                  <p />
                </div>
              </div>
              <div className="col-3 col-md-3 col-lg-2 offset-lg-1 res-margin col">
                <div className="widget">
                  {localStorage.getItem('webLang') == "tr" ?
                    <>
                      <h6>Sayfalar</h6>
                      <ul className="footer-menu">
                        <li>
                          <a href="/privacy">Gizlilik Politikası</a>
                        </li>
                        <li>
                          <a href="/eula">Terms of Use (EULA)</a>
                        </li>
                        <li>
                          <a href="/terms">KVKK</a>
                        </li>
                        <li>
                          <a href="/contact">İletişim</a>
                        </li>
                      </ul>
                    </>
                    :
                    <>
                      <h6>Pages</h6>
                      <ul className="footer-menu">
                        <li>
                          <a href="/privacy">Privacy Policy</a>
                        </li>
                        <li>
                          <a href="/eula">Terms of Use (EULA)</a>
                        </li>
                        <li>
                          <a href="/terms">Terms &amp; Conditions</a>
                        </li>
                        <li>
                          <a href="/contact">Contact Us</a>
                        </li>
                      </ul>
                    </>
                  }
                </div>
              </div>
              <div className="col-3 col-md-3 col-lg-2 res-margin col">
                <div className="widget">

                  <h6>Dil</h6>
                  <ul className="footer-menu">
                    <li>
                      <a onClick={() => setLanguage("tr")}>Türkçe</a>
                    </li>
                    <li>
                      <a onClick={() => setLanguage("en")}>English</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-3 col-md-6 col-lg-3 offset-lg-1 col">
                <div className="widget">
                  <h6>Download</h6>
                  <div className="button-store">
                    <a href="https://play.google.com/store/apps/details?id=com.votingen" target="_blank" className="custom-btn d-inline-flex align-items-center m-2 m-sm-0 mb-sm-3">
                      <i className="fab fa-google-play" />
                      <p>Available on <span>Google Play</span>
                      </p>
                    </a>
                    <a href="https://apps.apple.com/tr/app/votingen/id1455725718" target="_blank" className="custom-btn d-inline-flex align-items-center m-2 m-sm-0">
                      <i className="fab fa-apple" />
                      <p>Download on <span>App Store</span>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-12 col">
                <p className="copyright text-center">Copyright © 2022 | <a href="https://it.devbu.com" target="_blank">Devbu Bilişim A.Ş.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {localStorage.getItem('webLang') == "tr" ?
        <CookieConsent buttonText="Kabul Ediyorum">
          Kullanıcı deneyimini arttırmak ve sitemizden en iyi şekilde faydalanabilmeniz için çerezler
          kullanılmaktadır. Sitemize giriş yaparak çerez kullanımını kabul etmiş bulunmaktasınız. {' '}
          <a href="/terms" target="_blank">
            Detaylı Bilgi
          </a>
        </CookieConsent>
        :
        <CookieConsent buttonText="Accept All">
          We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies. {' '}
          <a href="/terms" target="_blank">
            Read More
          </a>
        </CookieConsent>
      }
    </>
  )
}

export default AppFooter
