import axios from '../api/axios'
const token =localStorage.getItem('token')
export default class PlaylistService {
 

  async getMePlaylist(place_id) {
    
  
    const url = "/api/v1/youtube/myPlaylist"
    const headers={headers: { Authorization: `Bearer ` + token }}
    
    const data = {place_id}
    return axios.post(url,
     data,headers
        )
    .then(res =>{ return res})
    .catch(err=>{return;})
 
  }

  async getByPlaylistId (place_id,playlist_id){
    
    if(playlist_id==="" || playlist_id===null || playlist_id===false || playlist_id ===undefined){
      return;
    }else {
    
    const url = "/api/v1/youtube/getPlaylist"
    const headers={headers: { 'Content-Type': 'application/json', Authorization: `Bearer ` + token }}
    const data = {place_id,playlist_id}


    return axios.post(url,data,headers).then(res=> {return res}).catch(err=>{return;})
  }
  }

  





}