import React, { useEffect, useState } from 'react'
//Sticky-Header
import 'antd/dist/antd.css'

const Home = () => {
  const [top, setTop] = useState(72) //sticky-header

  return (
    <>
      <section id="home" className="banner image-bg">
      <div className="container">
        <div className="align-items-center row">
          <div className="col-12 col-lg-6 res-margin col">
            <div className="banner-text">
              <h1 className="wow fadeInUp" data-wow-offset={10} data-wow-duration="1s" data-wow-delay="0s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0s'}}>{localStorage.getItem('webLang') == "tr" ? <>SIRADAKİ ŞARKI <br />SENDEN GELSİN</> : <>YOU DECIDE<br />NEXT SONG</>}</h1>
              <p className="wow fadeInUp" data-wow-offset={10} data-wow-duration="1s" data-wow-delay="0.3s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.3s'}}>{localStorage.getItem('webLang') == "tr" ? <>Bulunduğun mekanda sevdiğin şarkı çalsın!</> : <>Most voted song will be the next one, Listen what you want!</>}</p>
              <div className="button-store wow fadeInUp" data-wow-offset={10} data-wow-duration="1s" data-wow-delay="0.6s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.6s'}}>
                <a href="https://play.google.com/store/apps/details?id=com.votingen" target="_blank" className="custom-btn d-inline-flex align-items-center m-2 m-sm-0 me-sm-3">
                  <i className="fab fa-google-play" />
                  <p>{localStorage.getItem('webLang') == "tr" ? <><span>Google Play</span>'den İndir!</> : <>Available on <span>Google Play</span></>}
                  </p>
                </a>
                <a href="https://apps.apple.com/tr/app/votingen/id1455725718" target="_blank" className="custom-btn d-inline-flex align-items-center m-2 m-sm-0">
                  <i className="fab fa-apple" />
                  <p>{localStorage.getItem('webLang') == "tr" ? <><span>App Store</span>'dan İndir!</> : <>Download on <span>App Store</span></>}
                  </p>
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col">
            <div className="banner-image wow fadeInUp" data-wow-offset={10} data-wow-duration="1s" data-wow-delay="0.3s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.3s'}}>
              <img className="bounce-effect" src="/images/single-welcome.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="wave-effect wave-anim"><div className="waves-shape shape-one"><div className="wave wave-one" /></div><div className="waves-shape shape-two"><div className="wave wave-two" /></div><div className="waves-shape shape-three"><div className="wave wave-three" /></div></div>
      </section>
        
    </>
  )
}

export default Home