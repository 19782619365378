
import { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useAuth from "../../hooks/useAuth";
import { GoogleAuthProvider, signInWithPopup, FacebookAuthProvider, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import { useNavigate, useLocation, Link } from "react-router-dom"
import axios from '../../api/axios';

const LOGIN_URL = '/login'

const Login = (props, context) => {
    const googleProvider = new GoogleAuthProvider();
    const facebookProvider = new FacebookAuthProvider();

    const { setAuth ,lang} = useAuth();

    
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";


    const emailRef = useRef();
    const errRef = useRef();
    const passwordRef = useRef();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        if (!localStorage.getItem('token')) emailRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email, password])

    const signIn = async (e) => {

        const response = signInWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value).then(async result => {
            // setFirebaseToken('');
            const firebase_token = result?.user?.accessToken
            return firebase_token

        }).catch(err => {
            setErrMsg("Wrong password or email")
        })
        return response;
    }


    const signInWithGoogle = () => {
        signInWithPopup(auth, googleProvider)
            .then(async (result) => {

                const firebase_token = result?.user?.accessToken
                try {
                    const response = await axios.post(LOGIN_URL,
                        { firebase_token }
                    );
                    //console.log(JSON.stringify(response));
                    const accessToken = response?.data?.token;
                    localStorage.setItem("token", accessToken)

                    setEmail('');
                    setPassword('');

                    navigate(from, { replace: true })
                    //   window.location.reload() //?

                } catch (err) {
                    if (!err?.response) {
                        setErrMsg('No Server Response');
                    } else if (err.response?.status === 400) {
                        setErrMsg('Missing Username or Password');
                    } else if (err.response?.status === 401) {
                        setErrMsg('Unauthorized');
                    } else {
                        setErrMsg('Login Failed');
                    }
                    errRef.current.focus();
                }
            }).catch(error => { console.log(error) })
    }

    const signInWithFacebook = () => {
        signInWithPopup(auth, facebookProvider)
            .then(async (result) => {

                const firebase_token = result?.user?.accessToken

                try {
                    const response = await axios.post(LOGIN_URL,
                        { firebase_token }
                    );

                    //console.log(JSON.stringify(response));
                    const accessToken = response?.data?.token;
                    localStorage.setItem("token", accessToken)


                    setEmail('');
                    setPassword('');

                    navigate(from, { replace: true })
                    // window.location.reload() //??

                } catch (err) {
                    if (!err?.response) {
                        setErrMsg('No Server Response');
                    } else if (err.response?.status === 400) {
                        setErrMsg('Missing Username or Password');
                    } else if (err.response?.status === 401) {
                        setErrMsg('Unauthorized');
                    } else {
                        setErrMsg('Login Failed');
                    }
                    errRef.current.focus();
                }
            })
            .catch(err => console.log(err))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            const firebase_token = await signIn();
            const response = await axios.post(LOGIN_URL,
                { firebase_token }
            );

            //console.log(JSON.stringify(response));
            const accessToken = response?.data?.token;
            localStorage.setItem("token", accessToken)
            setAuth({ accessToken: accessToken })
            setEmail('');
            setPassword('');
            navigate(from, { replace: true })
            // window.location.reload() //??


        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                console.log(err)
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }


    return (
        <>
            <section className="page">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-8 res-margin col">
                            <article className="row blog-post">

                                {localStorage.getItem('token') ? navigate(from, { replace: true }) :
                                    <>
                                        {/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}
                                        <h2>{lang.login.signIn}</h2>
                                        <form onSubmit={handleSubmit}>
                                            <label htmlFor="Email" class="form-label">{lang.login.email}:</label>
                                            <input
                                                type="email"
                                                id="email"
                                                ref={emailRef}
                                                autoComplete="off"
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={email}
                                                required
                                                className="form-control"
                                            />
                                            <br />
                                            <label htmlFor="password" class="form-label">{lang.login.password}:</label>
                                            <input
                                                type="password"
                                                id="password"
                                                onChange={(e) => setPassword(e.target.value)}
                                                ref={passwordRef}
                                                value={password}
                                                required
                                                className="form-control"
                                            />
                                            <br />
                                            <button className="btn btn-primary" >{lang.login.signIn}</button>

                                            {/* <div id="recaptcha-container"></div> */}
                                            <br />

                                            <button className="btn btn-primary btn-google" onClick={signInWithGoogle}>{lang.login.googleLogin}</button>
                                            <button className="btn btn-primary btn-facebook" onClick={signInWithFacebook}>{lang.login.facebookLogin}</button>

                                        </form>




                                        <p className='margin-top-20'>
                                            {lang["login"]["noAccount"]}<br />
                                            <span > {/*className="line" */}
                                                {/*put router link here*/}
                                                <Link to="/register" >{lang.login.register}</Link>
                                            </span>
                                        </p>
                                    </>
                                }

                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login
