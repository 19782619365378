import {useLocation,Navigate,Outlet} from "react-router-dom";
// import useAuth from "../hooks/useAuth";


const RequireAuth = ()=> {
    // const {auth} = useAuth();
    const location =useLocation();

    return (
        !localStorage.getItem('token')
        ? <Navigate to="/login" state={{from:location}} replace/>
        : <Outlet/>
    )
}


export default RequireAuth;