import React,{ useEffect } from 'react';
import { useState } from 'react';
import PlaylistService from "../../services/PlaylistService"

function Playlist(props) {

    const [playlists,setPlaylists]=useState([]);
    
    const place_id=props?.place?._id
    let playlistService = new PlaylistService();
    useEffect(()=> {
        playlistService.getMePlaylist(place_id)
        .then(res=>{setPlaylists(res?.data)
        })
        .catch(err=>console.error(err))
    },[])
    const playlistLength=playlists?.length;
// console.log(props.onPlaylistSelected)
    return (
        <>
        <div className="container">
        <h4>Kendi Playlistlerim</h4>
            {playlists && playlists?.map((playlist,index)=> {
                return (
                  
                	<li key={index} onClick={()=> {props.onPlaylistSelected(playlist)}} className="list-group-item">
			<div className="video-list media">
				<div className="media-left">
					<img className="media-object mr-6" src={playlist.thumbnail}/>
				</div>
				<div className="media-body">
					<div className="media-heading">
                        <h5 className="mt-0"><b>Playlist Adı :</b> {playlist.title}</h5>
                        <br/>
						<span>{playlist.description=="" ? "Açıklama eklenmemiş" : playlist.description}</span>
					</div>
                    <div className="media-body">
                        <h5 className="media-"></h5>
                    </div>
				</div>
			</div>
		</li>
               
                    
                )
            })}
                 </div>            
        </>
    )
}

export default Playlist
