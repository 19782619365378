import React, { useRef, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import emailjs from 'emailjs-com'

const Thanks = (props) => {
    const form = useRef();
    const result = useRef(null);

    return (
        <>
            {localStorage.getItem('webLang') == "tr" ?

                <section className="page">

                    {/* <!-- Container --> */}
                    <Container>

                        <Row>

                            {/* <!-- Contact info --> */}
                            <div className="contact-info col-12 col-lg-6 res-margin">

                                {/* <!-- Section title --> */}
                                <div className="section-title text-center text-lg-start">
                                    <h3>Teşekkürler!</h3>
                                    <p>
                                        Spotify hesabınız başarıyla Votingen mekanınıza bağlanmıştır. Spotify'dan bir playlist açarak oylamaya açık hale getirebilirsiniz.
                                    </p>
                                </div>

                            </div>
                        </Row>

                    </Container>

                </section>
                : 
        
                <section className="page">
			
                {/* <!-- Container --> */}
                <Container>
                    
                    <Row>
                        
                        {/* <!-- Contact info --> */}
                        <div className="contact-info col-12 col-lg-6 res-margin">
                            
                            {/* <!-- Section title --> */}
                            <div className="section-title text-center text-lg-start">
                                <h3>Thank you!</h3>
                                <p>
                                    You have successfully connected your Spotify account to your place in Votingen. You can start playing songs from a playlist to make it votable.
                                </p>
                            </div>
                            
                        </div>
                    </Row>
                    
                </Container>
                
            </section>
        }
        </>
    );
}

export default Thanks;