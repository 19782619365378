import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

// Images
import useAuth from '../../hooks/useAuth';
import ProfileService from '../../services/ProfileService';

const Sidebar = () => {

    let profileService = new ProfileService();


    const [profile, setProfile] = useState();

    useEffect(() => {
        profileService.getProfile().then((profile) => {
            setProfile(profile.data);
        }).catch(err => {
            console.error(err)
        })

    }, [])

    const { lang } = useAuth();

    return (
        <div className="sidebar-wrapper col-12 col-lg-4">
			{/* <!-- Author --> */}
			<Row className="sidebar">
				<Col className="col-12">
					
					<div className="author-content">
						<img src={profile?.photo || "/images/empty_avatar.png"} alt="" width={100} height={100} />
						<h5>{profile?.firstName+" "+profile?.lastName}</h5>
						<p className="position"><i className="fa fa-coins"></i> {profile?.point}</p>
					</div>
				</Col>
			</Row>
			
			{/* <!-- Categories --> */}
			<Row className="sidebar">
				<Col className="col-12">

					<header>
						<h4>{lang.place.account}</h4>
					</header>

					<ul className="menu">
						<li><a href="blog">{lang.place.balance}</a></li>
						<li><a href="blog">{lang.place.invoices}</a></li>
						<li><a href="blog">{lang.place.orderOperationReports}</a></li>
						<li><a href="blog">{lang.place.contactDetails}</a></li>
						<li><a href="blog">{lang.place.companyDetails}</a></li>
						<li><a href="blog">{lang.place.bankDetails}</a></li>
						<li><a href="blog">{lang.place.userDetails}</a></li>
						<li><a href="blog">{lang.place.files}</a></li>
						<li><a href="blog">{lang.place.settings}</a></li>
						<li><a href="blog">{lang.place.contactPreferences}</a></li>
					</ul>

				</Col>
			</Row>
			
		</div>
    );
};

export default Sidebar;
