import React,{useState} from 'react'
import GoogleMapReact from 'google-map-react';
export default function AddPlace() {
    const AnyReactComponent = ({ text }) => <div>{text}</div>;
    const handleSubmit = ()=> {}

    const [lat,setLat]=useState(null);
    const [long,setLong]=useState(null);
    const [status,setStatus]=useState(null);


    const getLocation = () => {
        if (!navigator.geolocation) {
          setStatus('Geolocation is not supported by your browser');
        } else {
          setStatus('Locating...');
          navigator.geolocation.getCurrentPosition((position) => {
            setStatus(null);
            setLat(position.coords.latitude);
            setLong(position.coords.longitude);
          }, () => {
            setStatus('Unable to retrieve your location');
          });
        }
      }


    const defaultProps = {
        center: {
          lat: 39.993344,
          lng: 32.8105984
        },
        zoom: 45
      };


  return (
    <section>
                    {/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}
                    <h1>Yeni Mekan Oluşturma</h1>
                    <form onSubmit={handleSubmit}>
                    <button onClick={getLocation}>Get Location</button>
                    <h1>Coordinates</h1>
      <p>{status}</p>
      {lat && <p>Latitude: {lat}</p>}
      {long && <p>Longitude: {long}</p>}
                    <label htmlFor="map">
                            Konum seç
                        </label>
                        <div style={{ height: '50vh', width: '50%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={59.955413}
          lng={30.337844}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
                        <label htmlFor="place_name">
                            Mekan adı:
                        </label>
                        <input
                            type="text"
                            id="place_name"
                            autoComplete="off"
                            required
                        />
                        <label htmlFor="about">
                            Hakkınızda:
                        </label>
                        <input
                            type="text"
                            id="about"
                        />



                        <label htmlFor="phone">
                            Telefon
                          
                        </label>
                        <input
                            type="tel"
                            id="phone"
                            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                        />
                         <label htmlFor="reference">
                            Referans
                          
                        </label>
                        <input
                            type="text"
                            id="refence" 
                        />
                        <label htmlFor="upload_img">
                            Resim Yükle
                        </label>
                        <input
                            type="file"
                            id="upload_img" 
                        />

                        

                        <button>Oluştur</button>
                    </form>
                   
                </section>
  )
}
