import axios from '../api/axios';
const token =localStorage.getItem('token');
const headers={headers: { Authorization: `Bearer ` + token}}

console.log("token", token);
export default class ProfileService {
  async getProfile() {
    const url = `/profile`
    const response= await axios.get(url,headers)
 
  return response;

  }
}