import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// import AppRoute from './utils/AppRoute';
import ReactGA from 'react-ga';
import ScrollReveal from './utils/ScrollReveal';
import Router from './routers/router';

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {
  const childRef = useRef();
  let location = useLocation();
  useEffect(() => {
    var selectedLang = localStorage.getItem('webLang');
    if (!selectedLang) {
      localStorage.setItem('webLang', window.navigator.language != "tr-TR" ? "en" : "tr");
    }
  }, []);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <>
          <Router />
        </>
      )} />
  );
}

export default App;