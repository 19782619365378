import React, { useState } from 'react'
import useLogout from "../../hooks/useLogout";
import { Link, useNavigate } from "react-router-dom";
import useAuth from '../../hooks/useAuth';

const AppHeader = (props) => {
  const {history} = props;
  const [showNavbar, setShowNavbar] = useState(false)
  const [menuDropdown, setMenuDropdown] = useState(false)
  const closeNavbar = () => setShowNavbar(!showNavbar)
  const navigate = useNavigate();
  const logout = useLogout();

  const { lang } = useAuth();

  const signOut = async () => {
    await logout();
    navigate('/login')
  }
  const toggleMenuDropdown = () => {
    setMenuDropdown(!menuDropdown)
  }

  const setLanguage = (lang) => {
    localStorage.setItem("webLang", lang);
    // setTimeout(function() {
    //   window.location.reload();
    // }, 2000)
  }

  return (
    <>
      <header id="top-page" className={window.location.pathname == '/' ? "header" : "header-subpages"}>
        <div id="mainNav" className="main-menu-area animated">
          <div className="container">
            <div className="align-items-center row position-relative">
              <div className="col-12 col-lg-2 d-flex justify-content-between align-items-center col">
                <div className="logo">
                  <a className="navbar-brand navbar-brand1" href="/">
                    <img src="/images/logo-white@2x.png" alt="votingen" />
                  </a>
                  <a className="navbar-brand navbar-brand2" href="/">
                    <img src="/images/logo@2x.png" alt="votingen" />
                  </a>
                </div>

                {/* <div className="menu-bar right-menu" role="button" tabIndex={0}>
                <div className="dropdown" style={{width: 100, float: "left"}}>
                    <button onClick={() => { toggleMenuDropdown(true) }} className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fa-solid fa-user"></i>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <Link className="dropdown-item" to="/place">{lang.place.myPlaces}</Link>
                    </div>
                  </div>
                  {localStorage.getItem("token") ?
                    <button className='btn btn-secondary header-button' onClick={signOut}>{lang["login"]["logout"]}</button>
                    : <button className='btn btn-secondary header-button' onClick={() => { navigate('login', { replace: true }) }}>{lang["login"]["signIn"]}</button>}
                </div> */}


              </div>
              <div className="op-mobile-menu col-lg-10 p-0 d-lg-flex align-items-center justify-content-end" role="button" tabIndex={0}>
                <div className="m-menu-header d-flex justify-content-between align-items-center d-lg-none">
                  <a href="/" className="logo">
                    <img src="/images/logo-white@2x.png" alt="votingen" />
                  </a>
                  <span className="close-button" role="button" aria-label="Close" tabIndex={0} />
                </div>
                <ul className="nav-menu d-lg-flex flex-wrap list-unstyled justify-content-center" />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default AppHeader