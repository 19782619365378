import {createContext,useState} from "react";


const AuthContext = createContext({});

const langTR = 
    {
        "login": {
            "signIn": "Giriş Yap",
            "email": "E-Posta",
            "password": "Şifre",
            "googleLogin": "Google ile Giriş yap",
            "facebookLogin": "Facebook ile Giriş yap",
            "noAccount": "Hesabınız Yok Mu?",
            "register": "Kayıt Ol",
            "logout": "Çıkış Yap",
        },

        "place": {
            "noMyPlace": "Henüz bir mekan eklemediniz. Mobil uygulamamız üzerinden mekan ekleyebilirsiniz.",
            "myPlaces": "Mekanlarım",
            "account": "Hesabım",
            "contactDetails": "İletişim Bilgileri",
            "companyDetails": "Şirket Bilgileri",
            "bankDetails": "Banka Bilgileri",
            "userDetails": "Kullanıcı Bilgileri",
            "files": "Belgelerim",
            "file1": "Üyelik Sözleşmesi",
            "file2": "Vergi Levhası",
            "file3": "İmza Sirküleri",
            "file4": "Faaliyet Belgesi",
            "file5": "Ticaret Sicil Belgesi",
            "settings": "Ayarlar",
            "contactPreferences": "İletişim Tercihleri",
            "balance": "Hakediş",
            "balancedue": "Vadesi Gelen Hakediş",
            "balanceToBePaid": "Ödenecek Tutar",
            "balanceDate": "Hakediş Tarihi",
            "balancePayDate": "Öngörülen Ödeme Tarihi",
            "balanceFuture": "Gelecek Dönem Hakedişiniz",
            "invoiceRequests": "Fatura Talepleri",
            "invoices": "Faturalar",
            "orderOperationReports": "Satış & Operasyon Raporları",
            "helpCenter": "Yardım Merkezi",
            "notificationCenter": "Bildirim Merkezi",
            "liveHelp": "Canlı Yardım",
            "adCenter": "Reklam Merkezi",
            "menuManager": "Menü Düzenleme"
        }
    }

const langEN = {
    "login": {
        "signIn": "Sign in",
        "email": "E-Mail",
        "password": "Password",
        "googleLogin": "Login with Google",
        "facebookLogin": "Login with Facebook",
        "noAccount": "Need an Account?",
        "register": "Sign up",
        "logout": "Logout",
    },


    "place": {
        "noMyPlace": "You have no place. You can add your place to Votingen from our mobile application",
        "myPlaces": "My Places",
        "account": "My Account",
        "contactDetails": "Contact Details",
        "companyDetails": "Company Details",
        "bankDetails": "Bank Details",
        "userDetails": "User Details",
        "files": "Files",
        "file1": "Agreement",
        "file2": "Tax Information",
        "file3": "Company Permission File",
        "file4": "Company Information",
        "file5": "Company Setup Documents",
        "settings": "Settings",
        "contactPreferences": "Contact Preferences",
        "balance": "Balance",
        "balancedue": "Balance Due",
        "balanceToBePaid": "Balance To Be Paid",
        "balanceDate": "Balance Date",
        "balancePayDate": "Balance Pay Date",
        "balanceFuture": "Balance Future",
        "invoiceRequests": "Invoice Requests",
        "invoices": "Invoices",
        "orderOperationReports": "Order & Operation Reports",
        "helpCenter": "Help Center",
        "notificationCenter": "Notification Center",
        "liveHelp": "Live Help",
        "adCenter": "Advertisement Center",
        "menuManager": "Menu Manager"

    }
}

export const AuthProvider = ({children})=> {
    const [auth,setAuth]=useState({});
    const [lang,setLang]=useState(localStorage.getItem("webLang") == "tr" ? langTR : langEN);
    
    const [placeDetail,setPlaceDetail]=useState([]);


    return (<AuthContext.Provider value={{auth,setAuth,placeDetail,setPlaceDetail, lang,setLang}}>
        {children}
    </AuthContext.Provider>)
}

export default AuthContext;