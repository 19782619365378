import React, { useRef, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import emailjs from 'emailjs-com'

const ContactForm = (props) => {
    const form = useRef();
    const result = useRef(null);
    const [isSent, setIsSent] = useState(false);
    const [sending, setSending] = useState(false);

    const handleSubmit = (e) => {
        console.log("form", form);
        setSending(true)
        e.preventDefault()
        emailjs
            .sendForm(
                'service_3qm25hj', 'template_s8ynala', form.current, 'V8yu8CBnJVAjTXNow'
            )
            .then(
                (result) => {
                    setIsSent(true)
                    console.log(result.text)
                },
                (error) => {
                    console.log(error.text)
                }
            )
    }

    return (
        <>
            {localStorage.getItem('webLang') == "tr" ?

                <section className="page">

                    {/* <!-- Container --> */}
                    <Container>

                        <Row>

                            {/* <!-- Contact info --> */}
                            <div className="contact-info col-12 col-lg-6 res-margin">

                                {/* <!-- Section title --> */}
                                <div className="section-title text-center text-lg-start">
                                    <h3>İletişim</h3>
                                    <p>
                                        Formu doldurarak bize ulaşabilirsiniz.
                                    </p>
                                </div>

                            </div>

                            {/* <!-- Contact form --> */}
                            <Col className="col-12 col-lg-6">
                                {isSent ? <p>Teşekkür ederiz, en kısa zamanda dönüş yapacağız.</p> :
                                    <form id="contact-form" onSubmit={handleSubmit} ref={form}>

                                        <Row>
                                            <Col className="col-12 col-lg-6">
                                                <div className="form-group mt-2 mb-3">
                                                    <input type="text" name="name" id="name" className="form-control field-name" placeholder="Ad Soyad" />
                                                </div>
                                            </Col>
                                            <Col className="col-12 col-lg-6">
                                                <div className="form-group mt-2 mb-3">
                                                    <input type="email" name="email" id="email" className="form-control field-email" placeholder="E-Posta" />
                                                </div>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col className="col-12 col-lg-12">
                                                <div className="form-group mt-2 mb-3">
                                                    <textarea name="message" rows="4" id="message" className="form-control field-message" placeholder="Mesaj"></textarea>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="col-12 col-lg-12 mt-2">
                                                <button type="submit" id="contact-submit" name="send" className="btn" disabled={sending}>Gönder</button>
                                            </Col>
                                        </Row>

                                    </form>
                                }

                                {/* <!-- Submit Results --> */}
                                <div className="contact-form-result" ref={result}>
                                    <h4>Thank you for the e-mail!</h4>
                                    <p>Your message has already arrived! We'll contact you shortly.</p>
                                </div>

                            </Col>

                        </Row>

                    </Container>

                </section>
                : 
        
                <section className="page">
			
                {/* <!-- Container --> */}
                <Container>
                    
                    <Row>
                        
                        {/* <!-- Contact info --> */}
                        <div className="contact-info col-12 col-lg-6 res-margin">
                            
                            {/* <!-- Section title --> */}
                            <div className="section-title text-center text-lg-start">
                                <h3>Contact Us</h3>
                                <p>
                                    You can always reach us by filling the form at right side.
                                </p>
                            </div>
                            
                        </div>
                        
                        {/* <!-- Contact form --> */}
                        <Col className="col-12 col-lg-6">
                            {isSent ? <p>Thank you for your interest. We'll reply asap.</p> :
                            <form id="contact-form" onSubmit={handleSubmit} ref={form}>                             
                                
                                <Row>
                                    <Col className="col-12 col-lg-6">
                                        <div className="form-group mt-2 mb-3">
                                            <input type="text" name="name" id="name" className="form-control field-name" placeholder="Name" />
                                        </div>
                                    </Col>
                                    <Col className="col-12 col-lg-6">
                                        <div className="form-group mt-2 mb-3">
                                            <input type="email" name="email" id="email" className="form-control field-email" placeholder="Email" />
                                        </div>
                                    </Col>                                
                                </Row>
                
                                
                                <Row>
                                    <Col className="col-12 col-lg-12">
                                        <div className="form-group mt-2 mb-3">
                                            <textarea name="message" rows="4" id="message" className="form-control field-message" placeholder="Message"></textarea>
                                        </div>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col className="col-12 col-lg-12 mt-2">
                                        <button type="submit" id="contact-submit" name="send" className="btn" disabled={sending}>Send Message</button>
                                    </Col>
                                </Row>
                                
                            </form>
                            }
                            
                            {/* <!-- Submit Results --> */}
                            <div className="contact-form-result" ref={result}>
                                <h4>Thank you for the e-mail!</h4>
                                <p>Your message has already arrived! We'll contact you shortly.</p>
                            </div>
                            
                        </Col>
                        
                    </Row>
                    
                </Container>
                
            </section>
        }
        </>
    );
}

export default ContactForm;