import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import PlaceService from "../services/PlaceService";
import YoutubeService from "../services/YoutubeService";
import { useNavigate, Link } from "react-router-dom"
import GoogleMaps from '../components/place/GoogleMaps';
function PlaceDetail() {
    let { placeId } = useParams();
    let placeService = new PlaceService();
    let youtubeService = new YoutubeService();

    const navigate = useNavigate();



    const [detail, setDetail] = useState();
    const [youtubeUrl, setYoutubeUrl] = useState("");
    useEffect(() => {
        placeService.getPlaceById(placeId).then((place) => {
            setDetail(place.data);
            console.log("place data", place.data)
        }).catch(err => {
            console.error(err)
        })

    }, [])

    const generateUrl = async () => {
        youtubeService.connect(placeId)
            .then(response => {
                setYoutubeUrl(response?.data?.oAuthUrlYoutube)
            })
            .catch(err => console.error(err))


    }
    return (

        <>
            <img src={detail?.cover_photo} width={"200px;"} height={"100px;"} />
            
            <GoogleMaps latitude={detail?.location?.coordinates[0].toString()} longitude={detail?.location?.coordinates[1].toString()} zoom={15}></GoogleMaps>

            <div className="container">
                <div className="row">
                    <div className="contact-info col-12 col-lg-6 res-margin">
                    <h3>{detail?.name}</h3>
                        {detail?.is_playing ?
                            <p>Oynayan Şarkı :{detail?.playing_song}</p>
                            : <p> Şarkı oynamıyor.</p>}
                        <span>Mekan Oyu : {detail?.places_vote}</span>
                        <br />
                        <span>Sosyal Medya : İnstagram Logo veya hangisi varsa soc_twitter,soc_facebook {detail?.soc_instagram}</span>
                        <br />
                        <span>Kategori : {detail?.category_id?.name_tr}</span>
                        <br />
                        Sosyal Medyada Paylaş : WP,FB,Twitter ICON {detail?.shareUrl}
                        <br />
                        <button onClick={generateUrl}>Youtube Bağla</button>
                        {youtubeUrl && <div><a
                            href={youtubeUrl} target="_blank">Bağlamak istiyorsanız tıklayınız</a></div>}

                    </div>

                </div>
            </div>
        </>
    )
}

export default PlaceDetail
